
import { defineComponent, ref, onMounted, watch } from 'vue'
import { notification } from 'ant-design-vue'
import { GroupOutlined } from '@ant-design/icons-vue'

import ICourse from '../../../interface/course'
import commonServices from '../../../services/common'
import courseService from '../../../services/course'
import searchService from '../../../services/filter'

export default defineComponent({
  components: {
    GroupOutlined
  },
  props: ['clientId', 'watchKey'],
  setup (props) {
    const params = new URLSearchParams(window.location.search).get('loginAs')
    const loginAs = ref(params ? '?loginAs=' + params : '')
    const columns = [
      {
        title: 'Courses',
        dataIndex: 'title'
      },
      {
        title: 'Price',
        dataIndex: 'price'
      },
      {
        title: 'Course Type',
        dataIndex: 'type'
      },
      {
        title: 'Total Credits',
        dataIndex: 'cpd_points'
      },
      {
        title: 'Current Balance',
        dataIndex: 'cpd_bal_points'
      }
    ]
    const courses = ref<Array<ICourse>>([])
    const courseData = ref<Array<ICourse>>([])
    const searchString = ref<string>('')
    const onSearch = async (searchValue: string) => {
      courses.value = searchService.search(courseData.value, searchValue)
    }
    const notify = async (message: string, description: string, type: string) => {
      notification[type]({ message, description })
    }
    const getCourse = async () => {
      const cId = commonServices.getClientId()
      const clientId = (props.clientId ? props.clientId : cId)
      const singleCourse = await courseService.getClientCoursesByType(clientId, 'Single')
      const groupCourse = await courseService.getClientCoursesByType(clientId, 'Group')
      courses.value = [...singleCourse.data, ...groupCourse.data]
      courseData.value = [...singleCourse.data, ...groupCourse.data]
    }
    watch(() => props.watchKey, () => {
      getCourse()
    })
    onMounted(() => {
      getCourse()
    })
    return {
      columns,
      onSearch,
      searchString,
      notify,
      courses,
      loginAs
    }
  }
})
