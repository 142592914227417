
import { defineComponent, ref, onMounted, createVNode, reactive, watch, toRefs } from 'vue'
import { LoginOutlined, HomeOutlined, ArrowLeftOutlined, ArrowRightOutlined, UserAddOutlined, ExclamationCircleOutlined, HeartOutlined, PlusOutlined } from '@ant-design/icons-vue'
import { useRoute } from 'vue-router'
import { notification, Modal } from 'ant-design-vue'
import { GoogleMap, Marker } from 'vue3-google-map'

import AddCandidate from '@/components/client/candidate/AddCandidate.vue'
import ListPlan from '@/components/agency/payment/ListPlans.vue'
import Listofcourse from '@/components/client/courses/Listofcourse.vue'

import profileService from '../../../services/profile'
import courseService from '../../../services/course'
import commonServices from '../../../services/common'

import IClient from '../../../interface/profile'
import ICourse from '../../../interface/course'

interface Coordinates {
  lat: number;
  lng: number;
}

export default defineComponent({
  components: {
    HomeOutlined,
    ArrowLeftOutlined,
    ArrowRightOutlined,
    UserAddOutlined,
    LoginOutlined,
    GoogleMap,
    Marker,
    AddCandidate,
    ListPlan,
    Listofcourse,
    PlusOutlined
  },
  setup () {
    const center = ref<Coordinates>()
    const route = useRoute()
    const clientId = ref<string>(route.params.id as string)
    const client = ref<IClient>()
    const candidates = ref<Array<IClient>>([])
    const activeKey = ref('1')
    const watchKey = ref<number>(0)
    const activeTabSub = ref('11')
    const visible = ref<boolean>(false)
    const courseListvisible = ref<boolean>(false)
    const loading = ref<boolean>(false)
    const availableCourse = ref([])
    const courses = ref<Array<ICourse>>([])
    const state = reactive({
      indeterminate: false,
      checkAll: false,
      checkedList: []
    })
    const next = async () => {
      let number = +activeKey.value
      number++
      activeKey.value = number.toString()
    }
    const prev = async () => {
      let number = +activeKey.value
      number--
      activeKey.value = number.toString()
    }
    const showCourseList = async () => {
      courseListvisible.value = true
    }
    const closeCourseList = () => {
      courseListvisible.value = false
    }
    const notify = async (message: string, description: string, type: string) => {
      notification[type]({
        message,
        description
      })
    }
    const onCheckAllChange = (e: any) => {
      const aCourse = []
      for (const course of availableCourse.value) {
        aCourse.push(course.value)
      }
      Object.assign(state, {
        checkedList: e.target.checked ? aCourse : [],
        indeterminate: false
      })
    }
    const getClientDetails = async () => {
      const responce = await profileService.getProfileDetails(clientId.value)
      client.value = responce.data
      if (responce.data.address) {
        const coordinates = responce.data.address.location.coordinates
        center.value = { lat: coordinates[0], lng: coordinates[1] }
      }
    }
    const getClientCandidates = async () => {
      try {
        const responce = await profileService.getCandidates(clientId.value)
        candidates.value = responce.data
      } catch (error) {
        await notify('Error', error.data, 'error')
      }
    }
    const addCandidateModel = async () => {
      visible.value = true
    }
    const handleCancel = async () => {
      visible.value = false
    }
    const refreshCandidate = async () => {
      getClientCandidates()
      handleCancel()
    }
    const deleteCandidate = async (data, index) => {
      try {
        // await profileService.deleteCandidate(data._id, data._id)
        const clientId = commonServices.getCurrentProfile()._id
        const subscriptionId = commonServices.getCurrentSubscription()._id
        await profileService.deleteCandidate({ profileId: data._id, clientId: clientId, subscriptionId: subscriptionId })
        candidates.value.splice(index, 1)
      } catch (error) {
        await notify('Error', error.data, 'error')
      }
    }
    const getUnAssignedCourse = async () => {
      try {
        availableCourse.value = []
        const responce = await courseService.getUnAssignedCourse(clientId.value)
        courses.value = responce.data
        for (const course of courses.value) {
          availableCourse.value.push({ label: course.title, value: course._id })
        }
      } catch (error) {
        loading.value = false
        await notify('Error', error.data, 'error')
      }
    }
    const assignCourse = async () => {
      if (state.checkedList.length === 0) {
        closeCourseList()
      }
      const enroll = []
      try {
        loading.value = true
        for (const courseId of state.checkedList) {
          enroll.push({ enrolled_date: new Date(), profile: clientId.value, course: courseId })
        }
        await courseService.enrollMultipleCourse(enroll)
        loading.value = false
        closeCourseList()
        watchKey.value += 1
      } catch (error) {
        loading.value = false
        await notify('Error', error.data, 'error')
      }
    }
    const showLoginConfirm = async (profile) => {
      console.log('profile', profile)
      Modal.confirm({
        title: 'Are you sure to login as candidate?',
        icon: createVNode(LoginOutlined),
        content: createVNode('div', { style: 'color:red;' }, 'Are you sure want to login as candidate?. This will login the user in new window.'),
        okText: 'Yes',
        okType: 'danger',
        cancelText: 'No',
        class: 'delte-confirm-modal',
        async onOk () {
          localStorage.setItem('loginAsProfile', JSON.stringify(profile))
          window.open('/candidate/course?loginAs=candidate', '_blank')
        },
        onCancel () {
          console.log('Cancel')
        }
      })
    }
    watch(() => state.checkedList, val => {
      state.indeterminate = !!val.length && val.length < availableCourse.value.length
      state.checkAll = val.length === availableCourse.value.length
      console.log('state', state)
    })
    const showDeleteConfirm = async (data, index) => {
      Modal.confirm({
        title: 'Are you sure?',
        icon: createVNode(ExclamationCircleOutlined),
        content: createVNode('div', {}, 'Do you really want to delete this record?. This process cannot be undo.'),
        okText: 'Delete',
        okType: 'danger',
        cancelText: 'Cancel',
        class: 'delte-confirm-modal',
        async onOk () {
          deleteCandidate(data, index)
        },
        onCancel () {
          console.log('Cancel')
        }
      })
    }
    onMounted(() => {
      getClientDetails()
      getClientCandidates()
      getUnAssignedCourse()
    })
    return {
      activeKey,
      prev,
      next,
      showDeleteConfirm,
      center,
      client,
      candidates,
      visible,
      addCandidateModel,
      handleCancel,
      clientId,
      refreshCandidate,
      showCourseList,
      closeCourseList,
      onCheckAllChange,
      loading,
      availableCourse,
      assignCourse,
      state,
      ...toRefs(state),
      courseListvisible,
      activeTabSub,
      watchKey,
      showLoginConfirm
    }
  }
})
